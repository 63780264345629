.services-header{
    width: 100%;
    text-align: center;
    margin-top: 140px;
    
}
.services-header h1{
    padding: 0;
    margin: 0;
    font-size: 2em;
    padding-bottom: 5px;
}
.services-header hr{
    border: 1px solid rgb(236,144,56);
    width: 90%;
    margin-bottom: 10px;
}
.services-container{
    width: 100%;
    display: flex;
    justify-content: center; 
    flex-wrap: wrap;
}

@media(min-width: 1000px){
    .services-header h1{
        font-size: 3em;
    }

    .services-header hr{
        width: 75%;
    }
}