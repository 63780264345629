.home-header{
    width: 100%;
    text-align: center;
    margin-top: 80px;
    
}
.home-header h1{
    padding: 0;
    margin: 0;
    font-size: 2em;
    padding-bottom: 5px;
}
.home-header hr{
    border: 1px solid rgb(236,144,56);
    width: 90%;
    margin-bottom: 10px;
}
.slider-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 120px;
    /* margin-top: 80px;      */
}
.slider{
    width: 100%;
}

@media(min-width:1200px){

    .home-header h1{
        font-size: 3em;
    }
    .slider-container{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;  
        padding: 30px;
    }
    .slider{
        width: 60%;
    }
    .home-header hr{
        width: 60%;
    }
}