.contact{
    margin-top: 100px;
}
.contact-header{
    width: 100%;
    text-align: center;
    margin-top: 80px;
    
}
.contact-header h1{
    padding: 0;
    margin: 0;
    font-size: 2em;
    padding-bottom: 5px;
}
.contact-header hr{
    border: 1px solid rgb(236,144,56);
    width: 90%;
    margin-bottom: 10px;
}

.contact-info-container{
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.info-item{
    padding: 20px;
    width: 300px;
}

.info-item h1{
    padding: 0;
    margin: 0;
    font-weight: bold;
    color: black;
    text-align: center;
}

.info-item p{
    text-align: center;
    padding: 0;
    margin: 2px;
    font-size: 1.4em;
}

.info-item img{
    margin: 0 110px;
}

.contact-link{
    color: black;
}

.contact-link:hover{
    color: rgb(236,144,56);
    text-decoration: none;
}

@media(min-width: 1000px){
    .contact-header h1{
        font-size: 3em;
    }

    .contact-header hr{
        width: 75%;
    }
}