.navbar{
    background-color: black;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 80;
}

.navbar img{
  width: 65px;
  padding: 5px 10px;
}

.ham-line {
  width: 35px;
  height: 2px;
  background-color: white;
  margin: 10px 0;
}

.ham{
    padding: 0 10px;
}
.navbar h1{
  display: none;
}

.header-text{
  display: none;
}
@media(min-width: 1200px){
  .ham{
    display: none;
  }
  .navbar img{
    display: none;
  }
  .navbar h1{
    display: block;
    text-decoration: none;
    margin: 20px 30px;
    font-size: 2.8em;
  }
  .navbar a{
    text-decoration: none;
  }

  .header-text{
    display: block;
  }


  .header-text span {
    position: relative;
  }

  .header-text span:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: rgb(236,144,56);
    transition: width 0.6s ease 0s, left 0.6s ease 0s;
    width: 0;
  }

  .header-text span:hover:after {
    width: 100%;
    left: 0;
  }
}

