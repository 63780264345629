.side-menu{
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: 200;
    background-color: whitesmoke;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
    overflow-y: scroll;
}

.side-menu a{
    text-decoration: none;
    color: black;
}

.side-menu a:active{
    color: rgb(236,144,56);
}

.menu-item{
    font-family: 'Play',sans-serif;
    border-bottom: solid 2px rgb(236,144,56);
    padding-bottom: 10px;
    margin-bottom: 50px;

}

@media (min-width: 1200px){
    .side-menu{
        display: none;
    }
}

.open{
    transform: translateX(0);
}

.close{
    transform: translateX(100%);
}