body {
  margin: 0;
  overflow-x: hidden;
}

html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

