.service-container {
  width: 300px;
  height: 400px;
  /* border: solid 2.4px rgb(236,144,56); */
  border-radius: 5%;
  /* background-color: rgb(211,212,214); */
  margin: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(236,144,56);
  color: black;
  -webkit-box-shadow: 6px 6px 23px -6px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 6px 6px 23px -6px rgba(0, 0, 0, 0.38);
  box-shadow: 6px 6px 23px -6px rgba(0, 0, 0, 0.38);
  transition: all 700ms ease;
}

.service-container h1{
  color:black;
  margin: 0;
  padding: 0;
  /* width: 200px; */
  text-align: center;
}

.service-container hr{
  border: 1px solid black;
  width: 200px;
}

.service-content{
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 10px;
  font-family: 'Open Sans',sans-serif;
  font-size: 1.1em;
  font-weight: bold;
}


.service-container img {
  padding: 30px;
}
