.about{
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center; 
    margin-top: 75px;
    margin-bottom: 55px;
}

.image-container{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.about-item{
    width: 90%;
    padding: 5px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-item h1{
    text-align: center;
    padding: 0;
    margin: 0;
    font-size: 2em;
    padding-bottom: 5px;
}
.about-item p{
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.6em;
    text-align: justify;
}

.about-item hr{
    border: 1px solid rgb(236,144,56);
    width: 100%;
    margin-bottom: 10px;
}

.about-img{
    width: 250px;
}

@media(min-width:1000px){
    .about{
        flex-direction: row;
        width: 70%;
    }
    .about-item{
        width: 100%;
        height: 100%;
        padding-top: 20px;
        align-self: flex-start;
        align-items: flex-start;

    }
    .about-item h1{
        text-align: left;
        font-size: 3em;
        padding: 0;
        margin: 0;
    }

    .about-img{
        width: 300px;
    }
}

@media(min-width:1500px){
    .about{
        flex-direction: row;
        width: 1100px;
    }
}